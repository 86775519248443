import React, { ReactElement, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import * as Styled from "./styles";
import Badge from "../../assets/illustration-deal-badge.inline.svg";
import { useVisibilityDetection } from "../../hooks/useVisibilityDetection";
import { SpellLike } from "../../components/SpellLike/SpellLike";
import { breakpoints } from "../../styles/variables";
import "./style.css";
import { PRICING_DATA_LTD } from "../../data/pricing";
const WORDS = ["ideas", "flows", "process", "plans", "concept", "problem"];

export const DealIntroSale = (): ReactElement => {
  const illustrationsRef = useRef(null);
  const { isVisible } = useVisibilityDetection(illustrationsRef);
  const pricing = {PRICING_DATA_LTD}.PRICING_DATA_LTD
  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={illustrationsRef} isVisible={isVisible}>
        <Styled.Section>
          <Styled.Content>
            <Styled.Heading>
              <Styled.Title>
                Create fast, simple sketches to visualize your
              </Styled.Title>
              <Styled.TitleWords>
                {" "}
                <span
                  css={css`
                    display: inline-block;
                    margin-right: -100%;
                    position: relative;
                    z-index: 10;

                    @media screen and (max-width: ${breakpoints.md}px) {
                      margin-right: 0;
                    }
                  `}
                >
                  <SpellLike
                    disabled={!isVisible}
                    minWidth={130}
                    words={WORDS}
                  />
                </span>
              </Styled.TitleWords>
              <Styled.SubTitle>
                And look like a HERO in front of your...
              </Styled.SubTitle>
              <Styled.SubTitle
                css={css`
                  margin: 0 !important;
                `}
              >
                clients, colleagues, community or class...
              </Styled.SubTitle>
            </Styled.Heading>

            <div id="mobile-display">
              <Styled.Figure>
                <img
                  src="/SW-video-demo2.gif"
                  srcSet="/SW-video-demo2.gif 1x, /SW-video-demo2.gif 2x"
                  alt="Illustration"
                  width={527}
                  loading="lazy"
                />
              </Styled.Figure>
              <Styled.DiscountSection>
                <div
                  css={css`
                    width: 30%;
                    transform: scale(0.8);
                  `}
                >
                  <Badge />
                </div>
                <div
                  css={css`
                    width: 60%;
                    transform: scale(0.8);
                    margin-top: -20px !important;
                  `}
                >
                  <Styled.PriceSection>
                    <Styled.OldPrice>Regular Price: $468/Year</Styled.OldPrice>
                    <Styled.CurrentPrice>Today:  {pricing[0].std}</Styled.CurrentPrice>
                    <Styled.PriceInfo>
                      ($0 Monthly/Yearly Fees. Forever){" "}
                    </Styled.PriceInfo>
                  </Styled.PriceSection>
                  <Styled.ButtonSection>
                    <Styled.Button href="#DealPricing">
                      Get SketchWow
                    </Styled.Button>
                    <Styled.AppInfo>For Windows & Mac computers</Styled.AppInfo>
                  </Styled.ButtonSection>
                </div>
              </Styled.DiscountSection>
              <div
                css={css`
                  clear: both;
                `}
              ></div>
            </div>

            <div id="desktop-displayDS">
              <Styled.DiscountSection>
                <Badge />
                <Styled.PriceSection>
                  <Styled.OldPrice>Regular Price: $468/Year</Styled.OldPrice>
                  <Styled.CurrentPrice>Today:  {pricing[0].std}</Styled.CurrentPrice>
                  <Styled.PriceInfo>
                    ($0 Monthly/Yearly Fees. Forever){" "}
                  </Styled.PriceInfo>
                </Styled.PriceSection>
                <Styled.ButtonSection>
                  <Styled.Button href="#DealPricing">
                    Get SketchWow
                  </Styled.Button>
                  <Styled.AppInfo>For Windows & Mac computers</Styled.AppInfo>
                </Styled.ButtonSection>
              </Styled.DiscountSection>
            </div>
          </Styled.Content>

          <div id="desktop-display">
            <Styled.Figure id="desktop-display">
              <img
                src="/SW-video-demo2.gif"
                srcSet="/SW-video-demo2.gif 1x, /SW-video-demo2.gif 2x"
                alt="Illustration"
                width={527}
                loading="lazy"
              />
            </Styled.Figure>
          </div>
        </Styled.Section>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
